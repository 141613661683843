.bg-login{
    width: 100%;
    min-height: 100vh;
    background-image: url('../../assets/img/bg-login.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.box-text{
    padding-top: 20px;
    padding-bottom: 20px;
}

.box-white{
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center
}

.input-text1{
    border: 1px solid #B5CA7D;
    border-radius: 37.5px;
}

.input-text2{
    border: 1px solid #b53d54;
    border-radius: 37.5px;
}

.input-text3{
    border: 1px solid #3d71b5;
    border-radius: 37.5px;
}

.input-group-text1{
    border: none;
    background-color: #FFFFFF;
    border: 1px solid #B5CA7D;
    border-top-left-radius: 37.5px;
    border-bottom-left-radius: 37.5px;
}

.input-group-text2{
    border: none;
    background-color: #FFFFFF;
    border: 1px solid #b53d54;
    border-top-left-radius: 37.5px;
    border-bottom-left-radius: 37.5px;
}

.input-group-text3{
    border: none;
    background-color: #FFFFFF;
    border: 1px solid #3d71b5;
    border-top-left-radius: 37.5px;
    border-bottom-left-radius: 37.5px;
}

.br-31{
    border-radius: 31px !important;
}

.box-input{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.box-width{
    width: 70%;   
}

.input1{
    border: 1px solid #B5CA7D;
    border-radius: 10px;
}

.input2{
    border: 1px solid #b53d54;
    border-radius: 10px;
}

.input3{
    border: 1px solid #3d71b5;
    border-radius: 10px;
}

.otp > input{
    font-size: 3em
}

.textJustify{
    text-align: justify !important;
}

.containers{
    gap: 4px
}

.containers > input{
    transform: scale(1.5)
}

.cursorNone{
    cursor:not-allowed;
}

.cursorPointer{
    cursor: pointer;
}

@media(max-width: 768px) {
    .box-width{
        width: 90%;   
    }

    .otp > input{
        font-size: 1em
    }
}