.containerBtn {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  .bar {
    min-width: 100%;
    min-height: 10px;
    position: relative;
    overflow: hidden;
  }
  .bar::after {
    content: "";
    min-height: 10px;
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    transform: translateX(-100%);
    animation: animate 2s infinite;
  }
  .bar::before {
    content: "";
    min-height: 10px;
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    transform: translateX(-100%);
    animation: animate 2s infinite;
    animation-delay: 1s;
  }
  @keyframes animate {
    0% {
      transform: translateX(-200%);
      min-width: 100px;
    }
    100% {
      transform: translateX(300%);
      min-width: 400px;
    }
  }
  