body{
    font-family: 'Poppins', sans-serif !important;
    font-weight: 300 !important;
}

.error{
    border: 2px solid #FF6565 !important;
}

.error-msg{
    color: #FF6565 !important;
}