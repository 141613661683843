.font-poppins-900{
    font-family: 'Poppins', sans-serif;
    font-weight: 900;
    letter-spacing: 2px;
}

.font-poppins-700{
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}

.font-poppins-600{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.font-poppins-500{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.font-poppins-400{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.font-poppins-300{
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
}