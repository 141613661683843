.item-navbar{
    margin: 0px 10px;
    font-weight:lighter !important;
    color: #000000;
}

.actived1{
    color: #B5CA7D !important;
    font-weight: bold !important;
}

.actived2{
    color: #b53d54 !important;
    font-weight: bold !important;
}

.actived3{
    color: #3d71b5 !important;
    font-weight: bold !important;
}

.actived-drop1 > a{
    color: #B5CA7D !important;
    font-weight: bold !important;
}

.actived-drop2 > a{
    color: #b53d54 !important;
    font-weight: bold !important;
}

.actived-drop3 > a{
    color: #3d71b5 !important;
    font-weight: bold !important;
}

.user-bg1{
    padding: 5px 15px;
    background-color: #b5ca7f80;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.user-bg2{
    padding: 5px 15px;
    background-color: #d28593;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.user-bg3{
    padding: 5px 15px;
    background-color: #8fb3e2;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.item-drodowns{
    font-size: 16px;
    font-weight: lighter !important;
    margin: 0px 10px;
}

@media screen and (max-width: 768px){
    .item-navbar{
        margin: 20px 0px;
    }

    .item-drodowns{
        margin: 0px;
    }
}