.input1{
    border: 1px solid #B5CA7D !important;
    border-radius: 10px;
}

.input2{
    border: 1px solid #b53d54 !important;
    border-radius: 10px;
}

.input3{
    border: 1px solid #3d71b5 !important;
    border-radius: 10px;
}

.input-select-green{
    border: none !important;
    border-radius: 10px !important;
}

.big{
    font-size: 1.1rem;
    padding: 10px 15px;
}